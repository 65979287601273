<script>
    import { createEventDispatcher } from "svelte";
    import { fly, fade } from "svelte/transition";

    const dispatch = createEventDispatcher();

    function closeModal() {
        dispatch("cancel");
    }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div transition:fade class="modal-backdrop" on:click={closeModal} />
<div transition:fly={{ y: 300 }} class="modal">
    <slot />
</div>

<style>
    .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.75);
        z-index: 100;
    }

    .modal {
        position: fixed;
        display: flex;
        align-items: center;
        z-index: 110;
    }
</style>
