<script>
    import { onDestroy, onMount } from "svelte";
    import { gsap } from "gsap";

    // see https://codepen.io/Mamboleoo/pen/XgBvrJ

    export let slotId;

    let element;

    let elementCenterX;
    let elementCenterY;
    let elementWidth;
    let elementHeight;

    let hover = false;

    var onMouseMoveEventListener = (event) => onMouseMove(event);
    var onResizeEventListner = (event) => calculatePosition(event);

    onMount(() => {
        element = document.getElementById(slotId);

        initGsap();
        calculatePosition();
        
        addEventListeners();
    });

    onDestroy(() => {
        removeEventListeners();
    });

    function initGsap() {
        let values = {
            x: 0,
            y: 0,
            scale: 1,
        };
        gsap.set(element, values);
    }

    function calculatePosition(e) {
        const boundingBox = element.getBoundingClientRect();
        elementCenterX = boundingBox.left + boundingBox.width / 2;
        elementCenterY = boundingBox.top + boundingBox.height / 2;
        elementWidth = boundingBox.width;
        elementHeight = boundingBox.height;
    }

    function onMouseMove(e) {
        let hoverArea = hover ? 0.7 : 0.5;
        let x = e.clientX - elementCenterX;
        let y = e.clientY - elementCenterY;
        let distance = Math.sqrt(x * x + y * y);
        if (distance < elementWidth * hoverArea) {
            hover = true;
            onHover(e.clientX, e.clientY);
        } else if (hover) {
            hover = false;
            onLeave();
        }
    }

    function onHover(posX, posY) {
        let values = {
            x: (posX - elementCenterX) * 0.4,
            y: (posY - elementCenterY) * 0.4,
            scale: 1.15,
            ease: "power2.out",
            duration: 0.4,
        };
        gsap.to(element, values);
        setZIndex(10);
    }

    function onLeave() {
        resetPosition();
        setZIndex(1);
    }

    function setZIndex(zIndex) {
        element.style.zIndex = zIndex;
    }

    function resetPosition() {
        let values = {
            x: 0,
            y: 0,
            scale: 1,
            ease: "elastic.out(1.2, 0.4)",
            duration: 0.75,
        };
        gsap.to(element, values);
    }

    function addEventListeners() {
        window.addEventListener("mousemove", onMouseMoveEventListener);
        window.addEventListener("resize", onResizeEventListner);
    }

    function removeEventListeners() {
        window.removeEventListener("mousemove", onMouseMoveEventListener);
        window.removeEventListener("resize", onResizeEventListner);
    }
</script>

<slot />
