import { readable } from "svelte/store";

const delayInMs = 1000;
let count = 0;

export const timer = readable(count, (set) => {
    const interval = setInterval(() => {
        count++;
        set(count)
    }, delayInMs);

    return () => {
        clearInterval(interval);
    }
});