<script>
    import { onDestroy, onMount } from "svelte";
    import { createScene, destroyScene } from "./cube.js";

    let container;

    onMount(() => {
        createScene(container);
    });

    onDestroy(() => {
        destroyScene(container);
    });
</script>

<div bind:this={container}></div>

<style>
    div {
        width: 200px;
        height: 140px;
        position: relative;
        display: block;
        margin-top: -16px;
        margin-left: -40px;
}
</style>

