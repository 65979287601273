<script>
    import Box from "./components/Box.svelte";
    import LinkBox from "./components/LinkBox.svelte";
    import ModalBox from "./components/ModalBox.svelte";
    import BitcoinPrice from "./components/BitcoinPrice.svelte";
    import { email, mailTo } from "./helpers/email.js";
    import Cube from "./components/Cube.svelte";

    const boxIdQuote = "quote";
    const boxIdEmail = "email";
    const boxIdWork = "work";
    const boxIdTwitter = "twitter";
    const boxIdGithub = "github";
    const boxIdBitcoin = "bitcoin";
    const boxIdPhotography = "photography";
    const boxIdAfricanjan = "africanjan";
    const boxIdRunning = "running";
    const boxIdCloudgorilla = "cloudgorilla";
    const boxIdBlog = "blog";
</script>

<Box id={boxIdQuote} title="Something to think about">
    <p>
        <a
            href="https://blockstream.info/tx/ca76384b91a600610e053f8ef8e55851071912a3fb6e1db6c6c312131980412d?expand"
            target="_blank"
            style="color:white"
        >
            "The most important thing is to enjoy your life - to be happy - it's
            all that matters."
        </a>
        <br />
        <br />
        <i>Audrey Hepburn</i>
    </p>
</Box>
<LinkBox id={boxIdEmail} title="Send me an email" link={mailTo()}>
    {email()}
</LinkBox>
<LinkBox
    id={boxIdWork}
    title="Business contact"
    link="https://www.linkedin.com/in/janrothen/"
/>
<LinkBox
    id={boxIdTwitter}
    title="Tweets"
    link="https://www.twitter.com/janrothen"
/>
<LinkBox
    id={boxIdGithub}
    title="What I do for a living"
    link="https://github.com/janrothen"
>
    <p>print "Hello, World!"</p>
    <Cube />
</LinkBox>
<ModalBox id={boxIdBitcoin} title="Send me some sats">
    <BitcoinPrice />
</ModalBox>
<LinkBox
    id={boxIdPhotography}
    title="Photo gallery"
    link="https://www.flickr.com/photos/janrothen"
>
    <p>flickr</p>
</LinkBox>
<LinkBox
    id={boxIdAfricanjan}
    title="Half a year in Tanzania, Uganda & Rwanda (2011-2012)"
    link="https://africanjan.tumblr.com"
/>
<LinkBox
    id={boxIdRunning}
    title="Physical performance tracking"
    link="https://connect.garmin.com/modern/profile/JanRothen"
>
    <p><i>running</i></p>
</LinkBox>
<LinkBox
    id={boxIdCloudgorilla}
    title="CloudGorilla: A Fast, Easy and Reliable Virtual Instances Manager"
    link="http://www.cloudgorilla.com"
/>
<LinkBox id={boxIdBlog} title="Blog" link="http://greetingsfromjan.tumblr.com">
    <p>read me</p>
</LinkBox>
<Box id="drawings" title=":-)" />
