import * as THREE from "three";

let container = null;
let scene = null;
let camera = null;
let renderer = null;
let frameId = null;
let cube = null;

export const createScene = (arg) => {
    container = arg;
    const width = container.offsetWidth;
    const height = container.offsetHeight;

    //console.dir(container);
    //console.log(width);
    //console.log(height);

    scene = new THREE.Scene();

    camera = new THREE.PerspectiveCamera(70, width / height, 0.1, 10000);
    camera.position.z = 2.5;

    renderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true,
    });
    renderer.setSize(width, height);
    container.appendChild(renderer.domElement);

    const geometry = new THREE.BoxGeometry(1, 1, 1);
    const material = new THREE.MeshBasicMaterial({
        wireframe: true,
        color: "#222",
    });

    cube = new THREE.Mesh(geometry, material);
    scene.add(cube);
    start();
};

export const destroyScene = (arg) => {
    stop();
    arg.removeChild(renderer.domElement);
};

function stop() {
    cancelAnimationFrame(frameId);
};

function start() {
    if (!frameId) {
        frameId = requestAnimationFrame(animate);
    }
};

function animate() {
    const factor = 0.005;
    cube.rotation.x += factor;
    cube.rotation.y += factor;
    cube.rotation.z += factor;
    renderScene();
    frameId = window.requestAnimationFrame(animate);
};

function renderScene() {
    renderer.render(scene, camera);
};