<script>
    import HoverEffectDecorator from "./HoverEffectDecorator.svelte";
    import Box from "./Box.svelte";

    export let id;
    export let title;
    export let link;
</script>

<HoverEffectDecorator slotId={id}>
    <Box {id} {title} {link}>
        <slot />
    </Box>
</HoverEffectDecorator>
