<script>
    export let id;
    export let link = null;
    export let title;
</script>

<article {id} class="box">
    {#if link}
        <a href={link} {title} target="_blank" rel="noopener noreferrer">
            <div class="content {id}" class:shadow={true}>
                <slot />
            </div>
        </a>
    {:else}
        <div class="content {id}">
            <slot />
        </div>
    {/if}
</article>

<style>
    .box {
        float: left;
        margin: 0px 30px 30px 0px;
        position: relative; /* necessary for hoverboxes to overlap */
    }
</style>
