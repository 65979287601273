<script>
	import Boxes from "./Boxes.svelte";
</script>

<main>
	<Boxes />
	<div id="lastUpdated">
		<code>last updated in 2023</code>
	</div>
</main>

<style>
	#lastUpdated {
		float: left;
		width: 200px;
		margin: -5px 30px 30px 0px;
}
</style>
