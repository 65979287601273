<script>
    import { createEventDispatcher } from "svelte";

    import Modal from "./Modal.svelte";

    const dispatch = createEventDispatcher();

    function closeModal() {
        dispatch("cancel");
    }
</script>

<Modal on:cancel={closeModal}>
    <lightning-widget
        name="Jan Rothen"
        accent="#f7931a"
        to="jan@getalby.com"
        image="https://0.gravatar.com/avatar/bd9882d9f7fccd4758ce313d7ea576716f4bf7be546ba597987f7f9a5a73eebe?size=512"
        amounts="100,1000"
    />
</Modal>
