<script>
    import HoverEffectDecorator from "./HoverEffectDecorator.svelte";
    import Box from "./Box.svelte";
    import Payment from "./Payment.svelte";

    export let id;
    export let title;

    let editMode = null;

    function startEdit() {
        editMode = "edit";
    }

    function cancelEdit() {
        editMode = null;
    }
</script>

<HoverEffectDecorator slotId={id}>
    <Box {id} {title}>
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div on:click={startEdit} class="modal">
            <slot />
        </div>
    </Box>
</HoverEffectDecorator>

{#if editMode === "edit"}
    <Payment on:cancel={cancelEdit} />
{/if}

<style>
    .modal {
        height: 100%;
        width: 100%;
    }
</style>