<script>
    import { timer } from "../stores/timer-store";

    const currency = "CHF";
    const amount = 1;
    const uri = `https://blockchain.info/tobtc?currency=${currency}&value=${amount};`;

    const refetchDelayInSeconds = 22;
    let secondsUntilNextRefresh = refetchDelayInSeconds;

    let data = null;
    let error = null;
    let formattedPrice = null;

    $: if ($timer % refetchDelayInSeconds === 0) {
        fetchData();
    }

    $: if ($timer) {
        if (secondsUntilNextRefresh <= 0) {
            // reset counter
            secondsUntilNextRefresh = refetchDelayInSeconds;
        }
        secondsUntilNextRefresh = secondsUntilNextRefresh - 1;
    }

    $: if (data) {
        formattedPrice = priceFormatted(data);
    }

    async function fetchData() {
        try {
            const response = await fetch(uri);
            if (!response.ok) {
                throw new Error("Couldn't fetch data.");
            }
            data = await response.json();
        } catch (e) {
            console.dir(e);
            error = e.message;
        }
    }

    function priceFormatted(price) {
        const priceOf1Btc = amount / price;
        return `${priceOf1Btc.toFixed(2)} ${currency}`;
    }
</script>

<div id="content" class:refreshing={secondsUntilNextRefresh === 0}>
    <p>
        <i>
            {#if error}
                Error: {error}
            {:else if data}
                {formattedPrice}
            {:else}
                Loading...
            {/if}
        </i>
    </p>
    <p>
        {("0" + secondsUntilNextRefresh).slice(-2)}s
    </p>
</div>

<style>
    #content {
        display: flex;
        justify-content: space-between;
    }

    .refreshing {
        color: #f7931a;
    }
</style>
