
export function email() {
    var p1 = '&#106;&#97;&#110;&#46;&#114;&#111;&#116;&#104;&#101;&#110;';
    var p2 = '&#64;';
    var p3 = '&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;';
    return decodeHtml(p1 + p2 + p3);
}

export function mailTo() {
    return 'mailto:Jan%20Rothen%20<' + email() + '>';
}

export function decodeHtml(html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
}